import React, { useMemo } from 'react';
import {
  Button,
  Col,
  Form,
  Offcanvas,
  OffcanvasProps,
  Row
} from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { PatientFlag, PatientType } from '../../../types/patientManagement';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { patientDeleteReasons } from '../../../data/patientData';

interface PatientFlagDialogProps {
  patient: PatientType;
  onSubmit: (data: PatientFlag) => void;
  onClose: () => void;
  loading: boolean;
  offCanvas: OffcanvasProps;
}

const PatientFlagForm: React.FC<PatientFlagDialogProps> = ({
  patient,
  offCanvas,
  onClose,
  loading,
  onSubmit
}) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();

  const initialValues: PatientFlag = useMemo(() => {
    return {
      is_flagged: patient?.is_flagged as number,
      flag_reason_key: patient?.flag_reason as string,
      flag_reason: patient?.flag_reason as string
    };
  }, [patient]);
  const validationSchema = Yup.object({
    is_flagged: Yup.number().required(
      t('component.form.validation.mandatory', {
        field: t('common.label.is_flagged').toLowerCase()
      })
    ),
    flag_reason: Yup.string().required(
      t('component.form.validation.mandatory', {
        field: t('common.label.flag_reason').toLowerCase()
      })
    )
  });
  const handleOnSubmit = (values: PatientFlag) => {
    onSubmit(values);
    console.log(values);
  };
  return (
    <Offcanvas onHide={onClose} {...offCanvas}>
      <Offcanvas.Header className="px-6 pt-6 pb-0" closeButton>
        <Offcanvas.Title id="example-modal-sizes-title-lg">
          <h4>
            Flag Patient&nbsp;-&nbsp;
            <small className="text-info">{patient?.full_name}</small>
          </h4>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-6 pb-6">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({
            values,
            handleBlur,
            handleChange,
            errors,
            touched,
            handleSubmit,
            setFieldValue
          }) => {
            return (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3  ">
                  <div className="d-flex justify-content-start align-items-center gap-4">
                    <Form.Check
                      type="checkbox"
                      id="delete"
                      label={t('common.label.delete')}
                      checked={values.is_flagged == 1}
                      onChange={e => {
                        setFieldValue('is_flagged', e.target.checked ? 1 : 0);
                        setFieldValue('flag_reason', '');
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="modify"
                      label={t('common.label.modify')}
                      checked={values.is_flagged == 2}
                      onChange={e => {
                        setFieldValue('is_flagged', e.target.checked ? 2 : 0);
                        setFieldValue('flag_reason', '');
                      }}
                    />
                  </div>
                  <small className="text-danger">
                    <ErrorMessage name="is_flagged" />
                  </small>
                </Form.Group>

                {values?.is_flagged == 1 && (
                  <>
                    <Form.Group className="mb-3  ">
                      {patientDeleteReasons?.map(reason => (
                        <Form.Check
                          type="radio"
                          key={`patientDeleteReasons-${reason}`}
                          id={`patientDeleteReasons-${reason}`}
                          label={t(reason)}
                          checked={values.flag_reason_key == reason}
                          onChange={e => {
                            setFieldValue(
                              'flag_reason_key',
                              e.target.checked ? reason : ''
                            );
                            if (reason == 'Other') {
                              setFieldValue('flag_reason', '');
                            } else {
                              setFieldValue('flag_reason', reason);
                            }
                          }}
                        />
                      ))}
                    </Form.Group>
                    {values?.flag_reason_key == 'Other' && (
                      <Form.Group className="mb-3">
                        <Form.Control
                          id="flag_reason_other"
                          type="text"
                          placeholder={t('common.label.other_reason')}
                          className={`form-control form-icon-input ${
                            touched.flag_reason && errors.flag_reason
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={values.flag_reason}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    )}
                    <small className="text-danger">
                      <ErrorMessage name="flag_reason" />
                    </small>
                  </>
                )}

                {values?.is_flagged == 2 && (
                  <Form.Group className="mb-3 text-start">
                    <textarea
                      rows={5}
                      name="flag_reason"
                      placeholder={t('common.label.need_to_modify')}
                      className="form-control form-icon-input"
                      value={values?.flag_reason || ''}
                      onChange={handleChange}
                    />
                    <small className="text-danger">
                      <ErrorMessage name="flag_reason" />
                    </small>
                  </Form.Group>
                )}
                <Row className="mt-4">
                  <Col sm={12} md={6}>
                    <Button
                      variant="subtle-secondary"
                      className="w-100 mb-3"
                      onClick={onClose}
                    >
                      <Trans t={t}>common.action.cancel</Trans>
                    </Button>
                  </Col>
                  <Col sm={12} md={6}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-100 mb-3"
                      disabled={loading}
                    >
                      <Trans t={t}>common.action.save</Trans>
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PatientFlagForm;
