import { Col, Row } from 'react-bootstrap';
import React from 'react';

const ReportingPage = () => {
  return (
    <div>
      <Row className="align-items-center mb-3">
        <Col>
          <h3 className="mb-0">Reporting</h3>
        </Col>
      </Row>
    </div>
  );
};
export default ReportingPage;
