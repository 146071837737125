import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { useTranslation } from 'react-i18next';
import { PatientType } from 'types/patientManagement';
import {
  getFirstAndLastName,
  getFullAltName,
  isTenantJevaia
} from 'helpers/utils';
import BlankTablePreview from 'components/common/BlankTablePreview';
import Button from '../../base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { convertTimestamp } from '../../../helpers/date';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const activityTypePatientsTableColumns = (
  handleOnView: (data: PatientType) => void
) => {
  const { t } = useTranslation();
  const isJevaia = isTenantJevaia();
  const columns: ColumnDef<PatientType>[] = [
    {
      header: t('common.label.patient_full_name'),
      accessorKey: 'full_name',
      cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-start align-items-center gap-2">
            <span
              className="text-primary"
              style={{ cursor: 'pointer' }}
              onClick={() => handleOnView(row.original)}
            >
              {getFirstAndLastName(row.original)}
            </span>
            {row.original.is_flagged ? (
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip>
                    <b>{row.original.is_flagged == 1 ? 'Delete' : 'Modify'}:</b>
                    <span className="ms-2">{row.original.flag_reason}</span>
                  </Tooltip>
                }
              >
                <div>
                  <Button
                    variant="outline-danger"
                    className="btn-circle"
                    style={{
                      marginLeft: '4px',
                      width: '1.2rem',
                      height: '1.2rem'
                    }}
                  >
                    <FontAwesomeIcon icon={faFlag} className="fs-10" />
                  </Button>
                </div>
              </OverlayTrigger>
            ) : (
              ''
            )}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-body' },
        headerProps: { style: { width: '20%' }, className: '' }
      }
    },
    ...(isJevaia
      ? []
      : [
          {
            header: `${t('common.label.patient_full_alt_name')}`,
            accessorKey: 'alt_last_name',
            // eslint-disable-next-line
            cell: ({ row }: any) => {
              return getFullAltName(row.original);
            },
            meta: {
              cellProps: { className: 'text-body' },
              headerProps: { style: { width: '10%' }, className: '' }
            }
          }
        ]),
    ...(isJevaia
      ? []
      : [
          {
            header: `${t('common.label.grade')}`,
            accessorKey: 'grade',
            meta: {
              cellProps: { className: 'text-body text-center' },
              headerProps: { style: { width: '5%' }, className: 'text-center' }
            }
          }
        ]),
    {
      header: `${t('common.label.gender')}`,
      accessorKey: 'gender',
      cell: ({ row }) => {
        const gender = row.original.gender;
        return gender?.toLowerCase() === 'male' ? 'Male' : 'Female';
      },
      meta: {
        cellProps: { className: 'text-body text-center' },
        headerProps: { style: { width: '10%' }, className: 'text-center' }
      }
    },
    {
      header: `${t('common.label.age')}`,
      accessorKey: 'age',
      meta: {
        cellProps: { className: 'text-body text-center' },
        headerProps: { style: { width: '5%' }, className: 'text-center' }
      }
    },
    {
      header: `${t('common.label.phone')}`,
      accessorKey: 'contact_number',
      meta: {
        cellProps: { className: 'text-body text-right' },
        headerProps: { style: { width: '15%' }, className: 'text-right' }
      }
    },
    {
      header: `${t('common.label.encounters')}`,
      accessorKey: 'encounter_count',
      meta: {
        cellProps: { className: 'text-body text-center' },
        headerProps: { style: { width: '10%' }, className: 'text-center' }
      }
    },
    {
      accessorKey: 'last_encounter_created',
      header: `${t('common.label.last_encounter_created')}`,
      cell: original => {
        const { row } = original;
        return (
          <span>
            {row.original?.last_encounter_created
              ? convertTimestamp(row.original?.last_encounter_created)
              : 'N/A'}
          </span>
        );
      },
      meta: {
        cellProps: { className: 'text-body text-right' },
        headerProps: { style: { width: '12%' }, className: 'text-right' }
      }
    }
  ];
  return columns;
};

const ActivityTypePatientsTable = ({ size }: { size?: number }) => {
  return (
    <div className="border-translucent">
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table border-translucent fs-9'
        }}
      />
      {size && size > 0 ? (
        <AdvanceTableFooter pagination className="py-3" />
      ) : (
        <BlankTablePreview />
      )}
    </div>
  );
};

export default ActivityTypePatientsTable;
