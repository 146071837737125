import moment from 'moment-timezone';
import {adToBs} from "@sbmdkl/nepali-date-converter";
import {getAuthFromLocalStorage} from "../utils/storage";

const timestampFormat = 'YYYY-MM-DD hh:mm';
const dateFormat = 'YYYY-MM-DD';
const timestampFormatPeriod = 'YYYY-MM-DD hh:mm a';

export const todayDate = () => {

    return moment().format('YYYY-MM-DD')
};
export const now = () => {

    return moment().format('YYYY-MM-DD-hh-mm')
};

// eslint-disable-next-line
export const convertADtoBs = (date: string) => {

    const bsDate: string = adToBs(date);
    return bsDate;
};
export const convertTimestamp = timestamp => {
    return timestamp
        ? moment(timestamp, timestampFormatPeriod).format(timestampFormatPeriod)
        : '';
};
export const convertTimestampToDate = timestamp => {
    return timestamp
        ? moment(timestamp, timestampFormat).format('YYYY-MM-DD')
        : '';
};
export const convertTimestampToHumanDate = timestamp => {
    return timestamp
        ? moment(timestamp, timestampFormat).format('MMMM Do , YYYY - hh:mm a')
        : '';
};
export const convertTimeToHumanTime = timestamp => {
    return timestamp ? moment(timestamp, timestampFormat).format('hh:mm a') : '';
};

export const checkExpiredEncounter = (timestamp, hour) => {

    const authStorage = getAuthFromLocalStorage();
    const user = authStorage.dhUser;
    if (user?.user_role?.is_deletable === 0) {
        return false
    }

    let newTimestamp = moment(timestamp).add(
        hour,
        'hours'
    ).utc().unix();
    return newTimestamp < moment().utc().unix();
};
export const isValidDate = (date: string) => {
    // console.log(date)
    // console.log(moment(date,dateFormat,true).isValid())
    return moment(date, dateFormat, true).isValid();
}
export const limitDateNepali = (date: string) => {
    return moment(date, dateFormat, true).utc() > moment('2000-01-01', dateFormat, true).utc();
}


export const convertTimestampToHumanDateTimezone = (dateValue) => {
    try {
        const authStorage = getAuthFromLocalStorage();
        // console.log(authStorage)
        const timezone = authStorage?.dhTenant?.timezone || null
        // console.log(moment(dateValue).unix())
        if (dateValue) {
            if (timezone) {
                return moment.utc(dateValue).tz(timezone).format('MMMM Do , YYYY - hh:mm a')
            } else {
                return convertTimestampToHumanDate(dateValue)
            }
        }
        return null;

    } catch (e) {
        console.log(e)
        return null
    }


};
