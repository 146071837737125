import { Navbar } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import NavbarBrand from 'components/navbars/nav-items/NavbarBrand';
import NavItems from 'components/navbars/nav-items/NavItems';
import NavItemsSlim from 'components/navbars/nav-items/NavItemsSlim';
import ChooseModal from 'components/dashboard/ChooseModal';
import { ActorType } from 'types/actorManagement';
import { useAppContext } from 'providers/AppProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { isTenantBLCP, isUserAdmin } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import useActorHook from 'hooks/actor-management/useActorHook';
import { useNavigate } from 'react-router-dom';
import { checkScope } from '../../../helpers/auth';
import { getAuthFromLocalStorage } from '../../../utils/storage';

const NavbarTopDefault = () => {
  const {
    config: { navbarTopShape, navbarTopAppearance }
  } = useAppContext();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { selectedActor, selectActor, clearSelectedActor } = useActorHook();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const auth = getAuthFromLocalStorage();
  useEffect(() => {
    if (auth) {
      if (!selectedActor && checkScope('encounter-list')) {
        setTimeout(() => {
          setIsModalOpen(true);
        });
      } else {
        setIsModalOpen(false);
      }
    }
  }, []);

  const handleActorChange = (
    actor: ActorType,
    isActorClick: boolean = true
  ) => {
    selectActor(actor);
    isActorClick && navigate('/');
  };

  const handleActorRemove = () => {
    clearSelectedActor();
    navigate('/');
  };

  const handleSelectedActorClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Navbar
        className={classNames('navbar-top fixed-top', {
          'navbar-slim': navbarTopShape === 'slim'
        })}
        expand
        variant=""
        data-navbar-appearance={
          navbarTopAppearance === 'darker' ? 'darker' : ''
        }
      >
        <div className="navbar-collapse">
          <NavbarBrand />
          <div className="navbar-collapse justify-content-between">
            {checkScope('encounter-list') && !isUserAdmin() ? (
              <div
                onClick={handleSelectedActorClick}
                style={{ cursor: 'pointer', fontSize: '14px' }}
              >
                <FontAwesomeIcon
                  className="me-2"
                  icon={faLocationDot}
                  style={{ color: selectedActor ? 'green' : 'grey' }}
                />
                {selectedActor ? (
                  <span>{selectedActor.name}</span>
                ) : (
                  <span>
                    Choose{' '}
                    {isTenantBLCP()
                      ? t('common.label.actor')
                      : t('common.label.location')}
                  </span>
                )}
              </div>
            ) : (
              <span></span>
            )}

            {navbarTopShape === 'default' ? <NavItems /> : <NavItemsSlim />}
          </div>
        </div>
      </Navbar>

      <ChooseModal
        modalOpen={isModalOpen}
        setModalOpen={setIsModalOpen}
        selectedActor={selectedActor}
        onSelectedActorChange={handleActorChange}
        handleActorRemove={handleActorRemove}
      />
    </>
  );
};

export default NavbarTopDefault;
