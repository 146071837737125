/* eslint-disable @typescript-eslint/no-explicit-any */
import IndeterminateCheckbox from 'components/base/IndeterminateCheckbox';
import React, { PropsWithChildren } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  InitialTableState,
  OnChangeFn,
  PaginationState,
  SortingState,
  useReactTable
} from '@tanstack/react-table';
import Button from '../components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faEye,
  faFlag,
  faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import { TableOptions } from '@tanstack/table-core';
import { useTranslation } from 'react-i18next';

export interface UseAdvanceTableProps<T> {
  columns: ColumnDef<T>[];
  data: T[];
  selection?: boolean;
  sortable?: boolean;
  action?: boolean;
  pagination?: boolean;
  pageSize?: number;
  pageCount?: number;
  selectionColumnWidth?: number | string;
  initialState?: InitialTableState;
  manualPagination?: boolean; // Enable server-side pagination
  manualFiltering?: boolean; // Enable server-side filtering
  manualSorting?: boolean; // Enable server-side global filtering
  onGlobalFilterChange?: (value: any) => void;
  onSortingChange?: OnChangeFn<SortingState>;
  onPaginationChange?: OnChangeFn<PaginationState>;
  onView?: (data: any) => void;
  onEdit?: (data: any) => void;
  onEditBooking?: (data: any) => void;
  onDelete?: (data: any) => void;
  onFlag?: (data: any) => void;
}

const selectionColumn = {
  id: 'select',
  accessorKey: '',
  header: ({ table }: any) => (
    <IndeterminateCheckbox
      className="form-check fs-8 mb-0"
      {...{
        checked: table.getIsAllRowsSelected(),
        indeterminate: table.getIsSomeRowsSelected(),
        onChange: table.getToggleAllRowsSelectedHandler()
      }}
    />
  ),
  cell: ({ row }: any) => (
    <IndeterminateCheckbox
      className="form-check fs-8 mb-0"
      {...{
        checked: row.getIsSelected(),
        disabled: !row.getCanSelect(),
        indeterminate: row.getIsSomeSelected(),
        onChange: row.getToggleSelectedHandler()
      }}
    />
  ),
  meta: {
    headerProps: { style: { width: '30px' } }
  }
};

const useAdvanceTable = <T,>({
  columns,
  data,
  selection,
  sortable,
  action = true,
  pagination,
  manualPagination = false,
  manualFiltering = false,
  manualSorting = false,
  onGlobalFilterChange,
  onSortingChange,
  onPaginationChange,
  pageSize,
  pageCount,
  initialState,
  onView,
  onEdit,
  onEditBooking,
  onDelete,
  onFlag
}: PropsWithChildren<UseAdvanceTableProps<T>>) => {
  const { t } = useTranslation();

  const state = {
    pagination: pagination
      ? { pageSize: pagination ? pageSize : data.length }
      : undefined,
    ...initialState
  };

  const actionColumn = {
    header: `${t('common.label.action')}`,
    id: 'action',
    cell: (original: any) => {
      const { row } = original;

      const removeEditIconList = [
        'encounter created',
        'encounter happened',
        'cancelled'
      ];

      const hideEditBooking = removeEditIconList.includes(
        row.original?.booking_status?.toLowerCase()
      );

      return (
        <>
          <span className="d-flex justify-content-end">
            {onView && (
              <Button
                variant="subtle-secondary"
                onClick={() => {
                  onView?.(row?.original || null);
                }}
                className="btn-circle ml-2"
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>
            )}

            {onEdit ? (
              <Button
                variant="subtle-info"
                className="btn-circle"
                onClick={() => {
                  onEdit?.(row?.original || null);
                }}
                style={{ marginLeft: '4px' }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            ) : (
              ''
            )}

            {onEditBooking && (
              <>
                {hideEditBooking ? (
                  ''
                ) : (
                  <Button
                    variant="subtle-info"
                    className="btn-circle"
                    onClick={() => {
                      onEditBooking?.(row?.original || null);
                    }}
                    style={{ marginLeft: '4px' }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                )}
              </>
            )}

            {onDelete &&
            (typeof row?.original?.is_deletable == 'undefined' ||
              row.original.is_deletable) ? (
              <Button
                variant="subtle-danger"
                className="btn-circle ml-2"
                style={{ marginLeft: '4px' }}
                onClick={() => {
                  onDelete?.(row?.original || null);
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </Button>
            ) : (
              ''
            )}
            {onFlag && (
              <Button
                variant="subtle-danger"
                className="btn-circle ml-2"
                style={{ marginLeft: '4px' }}
                onClick={() => {
                  onFlag?.(row?.original || null);
                }}
              >
                <FontAwesomeIcon icon={faFlag} />
              </Button>
            )}
          </span>
        </>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  };

  const handleColumns = () => {
    if (selection && action) {
      return [selectionColumn, ...columns, actionColumn];
    }

    if (selection) {
      return [selectionColumn, ...columns];
    }

    if (action) {
      return [...columns, actionColumn];
    }

    return [...columns];
  };

  const reactProps: TableOptions<T> = {
    data,
    columns: handleColumns(),
    pageCount: pageCount,
    enableSorting: sortable,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: manualPagination,
    manualFiltering: manualFiltering,
    manualSorting: manualSorting,

    initialState: state
  };
  if (manualPagination) {
    reactProps.manualPagination = manualPagination;
    reactProps.onPaginationChange = onPaginationChange;
  }
  if (manualFiltering) {
    reactProps.manualFiltering = manualPagination;
    reactProps.onGlobalFilterChange = onGlobalFilterChange;
  }
  if (manualSorting) {
    reactProps.manualSorting = manualSorting;
    reactProps.onSortingChange = onSortingChange;
  }

  const table = useReactTable<T>(reactProps);

  return table;
};

export default useAdvanceTable;
