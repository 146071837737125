import { useDispatch, useSelector } from '../../store';
import VenueService from '../../services/actor-management/VenueService';
import { Venue } from '../../types/actorManagement';
import {
  addOneVenue,
  removeOneVenue,
  setAllVenue,
  setOneVenue,
  UpdateOneVenue
} from '../../store/reducers/actor-mangement/venueSlice';
import { VenueFilterType } from '../../types';

const useVenueHook = () => {
  const dispatch = useDispatch();
  const { venues, venue, meta } = useSelector(state => state.venue);

  const fetchAllVenue = async (
    actorId?: string,
    schoolType?: string,
    venueTypeId?: string,
    activityTypeId?: string
  ): Promise<Venue[]> => {
    try {
      const resp = await VenueService.fetchAll(
        actorId,
        schoolType,
        venueTypeId,
        activityTypeId
      );
      const results = resp?.data?.data || [];
      dispatch(setAllVenue({ results }));
      return results;
    } catch (e) {
      console.log('fetchAllVenue', e);
      throw e;
    }
  };
  const fetchAllVenueWithPaginate = async (
    params: VenueFilterType
  ): Promise<Venue[]> => {
    try {
      const resp = await VenueService.fetchAllWithPaginate(params);
      const meta = resp?.data?.data?.meta || null;
      const results = resp?.data?.data?.rows || [];
      dispatch(setAllVenue({ results, meta }));

      return results;
    } catch (e) {
      console.log('fetchAllVenueWithPaginate', e);
      throw e;
    }
  };
  const fetchAllVenueByActivityId = async (
    activityTypeId?: string
  ): Promise<Venue[]> => {
    try {
      const resp = await VenueService.fetchAll(
        undefined,
        undefined,
        activityTypeId
      );
      const results = resp?.data?.data || [];
      dispatch(setAllVenue({ results }));
      return results;
    } catch (e) {
      console.log('fetchAllVenue', e);
      throw e;
    }
  };
  const createVenue = async (data: Venue): Promise<Venue> => {
    try {
      const resp = await VenueService.create(data);
      const row = resp?.data?.data || null;

      dispatch(addOneVenue({ row }));
      return row;
    } catch (e) {
      console.log('addOneVenue', e);
      throw e;
    }
  };
  const updateVenue = async (id: number, data: Venue) => {
    try {
      const resp = await VenueService.update(id, data);
      const row = resp?.data?.data || null;
      dispatch(UpdateOneVenue({ id, row }));
    } catch (e) {
      console.log('getOneVenue', e);
      throw e;
    }
  };
  const fetchOneVenue = async (
    id: number,
    shouldSave: boolean = true
  ): Promise<Venue> => {
    try {
      const resp = await VenueService.fetchOne(id);
      const row = resp?.data?.data || null;

      if (shouldSave) {
        dispatch(setOneVenue({ row }));
      }
      return row;
    } catch (e) {
      console.log('fetchOneVenue', e);
      throw e;
    }
  };
  const deleteVenue = async (id: number) => {
    try {
      await VenueService.destroy(id);
      dispatch(removeOneVenue({ id }));
    } catch (e) {
      console.log('getOneVenue', e);
      throw e;
    }
  };
  const toggleFlagVenue = async (id: number) => {
    try {
      const resp = await VenueService.toggleFlag(id);
      const row = resp?.data?.data || null;
      dispatch(UpdateOneVenue({ row }));
    } catch (e) {
      console.log('toggleFlagVenue', e);
      throw e;
    }
  };
  return {
    fetchAllVenue,
    fetchAllVenueByActivityId,
    createVenue,
    deleteVenue,
    fetchAllVenueWithPaginate,
    updateVenue,
    fetchOneVenue,
    toggleFlagVenue,
    venues,
    venue,
    meta
  };
};

export default useVenueHook;
