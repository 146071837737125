import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faRemove,
  faSchool,
  faUsers
} from '@fortawesome/free-solid-svg-icons';

import bg38 from 'assets/img/bg/38.png';
import useActorHook from '../../hooks/actor-management/useActorHook';
import useAuthHook from 'hooks/useAuthHook';
import PhoenixLoader from 'components/common/PhoenixLoader';
import BlankTablePreview from 'components/common/BlankTablePreview';
import { checkScope } from '../../helpers/auth';
import { isUserAdmin } from 'helpers/utils';
import { ActivityType, ActorType } from 'types/actorManagement';

interface ChooseModal {
  size?: 'lg' | 'sm' | 'xl' | undefined;
  modalOpen: boolean;
  setModalOpen: (show: boolean) => void;
  selectedActor: ActorType | null;
  onSelectedActorChange?: (actor: ActorType, isActorClick?: boolean) => void;
  handleActorRemove: () => void;
  activityId?: number;
}

interface CardProps {
  actor: ActorType;
}

const ChooseModal = ({
  size = 'xl',
  modalOpen,
  setModalOpen,
  selectedActor,
  onSelectedActorChange,
  handleActorRemove,
  activityId
}: ChooseModal) => {
  const navigate = useNavigate();
  //eslint-disable-next-line
  const { t }: { t: any } = useTranslation();

  const { tenant } = useAuthHook();

  const { actors, fetchAllActor } = useActorHook();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!checkScope('encounter-list') && modalOpen) navigate('/');
  }, [modalOpen]);

  useEffect(() => {
    if (modalOpen) {
      if (actors.length <= 0) {
        setIsLoading(true);
        fetchAllActor()
          .catch(e => console.log(e))
          .finally(() => setIsLoading(false));
      }
    }
  }, [modalOpen]);
  const mappedActors = useMemo(() => {
    if (activityId) {
      return actors.filter(
        data =>
          data.activity_types &&
          data.activity_types.find(item => item.id == activityId)
      );
    } else {
      return actors;
    }
  }, [activityId, actors]);

  const handleActorSelect = (actor: ActorType) => {
    if (onSelectedActorChange) {
      onSelectedActorChange(actor);
    }
    setModalOpen(false);
  };

  const handleActivityTypeSelect = (
    actor: ActorType,
    activityType: ActivityType
  ) => {
    navigate(
      `/activity-type/${activityType.id}/${activityType.venue_type_id}/actor/${actor.id}/schools`
    );
    if (onSelectedActorChange) {
      onSelectedActorChange(actor, false);
    }
    setModalOpen(false);
  };

  const handleRemoveSelectedActor = () => {
    handleActorRemove();
    setModalOpen(false);
  };

  const hexToRgba = (hex: string, opacity: number): string => {
    const cleanHex = hex.replace('#', '');

    const bigint = parseInt(cleanHex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const ActorCard = ({ actor }: CardProps) => {
    const isCurrentActor = selectedActor?.id === actor?.id;
    return (
      <Card
        className="clinic-type__item text-decoration-none border border-light overflow-hidden"
        style={{ cursor: 'pointer' }}
        onClick={() => handleActorSelect(actor)}
      >
        <div
          className="bg-card bg-holder d-block"
          style={{
            backgroundImage: `url(${bg38})`,
            backgroundPosition: 'left bottom',
            backgroundSize: 'inherit'
          }}
        />
        <Card.Body className="px-4 position-relative">
          <h3 className={`mb-3 ${isCurrentActor ? 'text-primary' : ''}`}>
            {actor.name}
          </h3>
          {!activityId && (
            <div className="mb-4 d-flex">
              {actor?.activity_types?.map((activityType, index) => (
                <span
                  className="clinic-type__activity-type"
                  key={index}
                  style={{
                    color: activityType?.color_code as string,
                    background: hexToRgba(
                      activityType?.color_code as string,
                      0.03
                    )
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    handleActivityTypeSelect(actor, activityType);
                  }}
                >
                  {activityType.name}
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    style={{ marginLeft: '6px' }}
                  />
                </span>
              ))}
            </div>
          )}

          <div className="d-flex justify-content-end gap-3">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faUsers}
                className="fs-10 form-icon text-body-quaternary"
              />
              <p className="fs-9 text-body-tertiary mb-0 ps-1">
                {actor.patient_count} {t('common.label.patient')}s
              </p>
            </div>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faSchool}
                className="fs-10 form-icon text-body-quaternary"
              />
              <p className="fs-9 text-body-tertiary mb-0 ps-1">
                {actor.venue_count} {t('common.label.venue')}s
              </p>
            </div>
            {/*<div className="d-flex align-items-center">*/}
            {/*  <FontAwesomeIcon*/}
            {/*    icon={faRepeat}*/}
            {/*    className="fs-10 form-icon text-body-quaternary"*/}
            {/*  />*/}
            {/*  <p className="fs-9 text-body-tertiary mb-0 ps-1">*/}
            {/*    {actor.total_recall} {t('common.label.recall')}s*/}
            {/*  </p>*/}
            {/*</div>*/}
            {/*<div className="d-flex align-items-center">*/}
            {/*  <FontAwesomeIcon*/}
            {/*    icon={faUserClock}*/}
            {/*    className="fs-10 form-icon text-body-quaternary"*/}
            {/*  />*/}
            {/*  <p className="fs-9 text-body-tertiary mb-0 ps-1">*/}
            {/*    {actor.total_triage} {t('common.label.triage')}*/}
            {/*  </p>*/}
            {/*</div>*/}
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Modal
      show={modalOpen}
      onHide={() => setModalOpen(false)}
      size={size}
      aria-labelledby="contained-modal-title-center"
      centered
      backdrop={'static'}
    >
      <Modal.Header className="d-flex justify-content-between align-items-center">
        <Modal.Title id="contained-modal-title-center">
          Choose{' '}
          {tenant?.name?.toLowerCase() === 'blcp'
            ? t('common.label.actor')
            : t('common.label.location')}
        </Modal.Title>
        <Modal.Title
          id="contained-modal-title-center"
          style={{ cursor: 'pointer' }}
          onClick={() => handleRemoveSelectedActor()}
        >
          {isUserAdmin() && (
            <FontAwesomeIcon
              icon={faRemove}
              className=" form-icon text-body-quaternary"
            />
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div
          className="clinic-type d-flex flex-wrap align-items-center justify-content-center"
          style={{ minHeight: '400px' }}
        >
          {isLoading ? (
            <PhoenixLoader />
          ) : (
            <>
              {mappedActors.length > 0 ? (
                <>
                  {mappedActors.map((actor, i) => (
                    <Fragment key={i}>
                      <ActorCard actor={actor} />
                    </Fragment>
                  ))}
                </>
              ) : (
                <BlankTablePreview text={`Please contact your admin.`} />
              )}
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChooseModal;
