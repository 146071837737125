import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { Col, OffcanvasProps, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import useAdvanceTable, { UseAdvanceTableProps } from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import SearchBox from 'components/common/SearchBox';
import PhoenixLoader from 'components/common/PhoenixLoader';
import usePatientHook from 'hooks/patient-management/usePatientHook';
import ActivityTypePatientsTable, {
  activityTypePatientsTableColumns
} from 'components/tables/dashboard/ActivityTypePatientsTable';
import {
  PatientFilterType,
  PatientFlag,
  PatientType
} from 'types/patientManagement';
import { SelectOptionType, validationErrorType } from 'types';
import { getFullName, isTenantJevaia, pageCount } from 'helpers/utils';
import { toast } from 'react-toastify';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFlag,
  faPlus,
  faRotate,
  faWarning
} from '@fortawesome/free-solid-svg-icons';
import useVenueHook from 'hooks/actor-management/useVenueHook';
import useLocationLevelHook from 'hooks/mdm/useLocationLevelHook';
import useLocationHook from 'hooks/mdm/useLocationHook';
import useActorHook from 'hooks/actor-management/useActorHook';
import { confirmAlert } from 'components/common/ConfirmAlert';
import useActivityTypeHook from 'hooks/actor-management/useActivityTypeHook';
import useFormOptionHook from 'hooks/settings/useFormOption';
import ReactSelect from 'components/base/ReactSelect';
import { checkScope } from '../../../helpers/auth';
import { TFunction } from 'i18next';
import { ageGroupOptions, flagOptions } from '../../../data/commonData';
import PatientForm from '../../../components/forms/patient-management/PatientForm';
import { Venue } from '../../../types/actorManagement';
import PatientDetail from '../../../components/details/patient-management/PatientDetail';
import PatientFlagForm from '../../../components/forms/patient-management/PatientFlagForm';

interface Props {
  isTab?: boolean;
  isWardPatients?: boolean;
  withVenue?: boolean;
}

const ActivityTypePatients = ({ isTab, isWardPatients, withVenue }: Props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkScope('encounter-list')) navigate('/');
  }, []);
  const { t }: { t: TFunction } = useTranslation();
  const page = t('page.title.list_of_patients');
  const patientTitle = t('page.title.patient');

  const { activityTypeId, actorId, venueId } = useParams();

  const {
    meta,
    patients,
    flagPatient,
    fetchAllPatient,
    createPatient,
    updatePatient
    // deletePatient
  } = usePatientHook();
  const { fetchOneVenue } = useVenueHook();
  const { locationLevels, fetchAllLocationLevel } = useLocationLevelHook();
  const { locations, fetchAllLocation } = useLocationHook();
  const { actor, fetchOneActor } = useActorHook();
  const { activityType, fetchOneActivityType } = useActivityTypeHook();
  const { fetchAllFormOption } = useFormOptionHook();
  const [activityVenue, setActivityVenue] = useState<Venue>();
  const [venue, setVenue] = useState<Venue>();
  const initialFormValues = useMemo(() => {
    return {
      id: null,
      actor_id: actorId ? parseInt(actorId) : null,
      venue_id: activityType?.has_locations_tab
        ? actor?.default_venue_id
        : venue?.id,
      location: [],
      grade: '',
      contact_number: '',
      address: '',
      education_level: '',
      gender: 'MALE',
      marital_status: 'SINGLE',
      caste_ethnicity: '',
      status: 'ACTIVE',
      first_name: '',
      last_name: '',
      middle_name: '',
      alt_first_name: '',
      alt_middle_name: '',
      alt_last_name: '',
      dob: '',
      age: '',
      medical_history: '',
      dental_history: '',
      other_comments: ''
    };
  }, [isWardPatients, venue, actor, activityType]);

  const [patient, setPatient] = useState<PatientType>(initialFormValues);

  const [loader, setLoader] = useState<{
    list?: boolean;
    form?: boolean;
    flag?: boolean;
  }>({
    list: false,
    flag: false,
    form: false
  });

  const [offCanvas, setOffCanvas] = useState<OffcanvasProps>({
    show: false,
    placement: 'end'
  });

  const [offCanvasDetail, setOffCanvasDetail] = useState<OffcanvasProps>({
    show: false,
    placement: 'end'
  });

  const [validationError, setValidationError] = useState<validationErrorType>({
    show: false
  });

  const [gradeOptions, setGradeOptions] = useState<SelectOptionType[]>([]);

  const [searchInput, setSearchInput] = useState<string>('');
  const [filter, setFilter] = useState<PatientFilterType>({
    page: 1,
    limit: 10,
    search: ''
  });
  const [gradeFilter, setGradeFilter] = useState<SelectOptionType | null>();
  const [flagFilter, setFlagFilter] = useState<SelectOptionType | null>();
  const [ageGroupFilter, setAgeGroupFilter] =
    useState<SelectOptionType | null>();
  const [offCanvasFlag, setOffCanvasFlag] = useState<OffcanvasProps>({
    show: false,
    placement: 'end'
  });

  const fetchAllFormOptionItem = (slug: string) => {
    fetchAllFormOption(slug)
      .then(results => {
        if (results.length > 0) {
          const mappedOptions: SelectOptionType[] = results.map(data => {
            return {
              label: data.label || '',
              value: data.value || ''
            };
          });
          setGradeOptions(mappedOptions);
        }
      })
      .catch(e => console.log(e));
  };
  useEffect(() => {
    // console.log('fetchNecessaryData');
    fetchNecessaryData().catch(e => console.log(e));
  }, [activityTypeId, actorId, venueId]);
  const loadPatients = async () => {
    setLoader({ ...loader, list: true });

    const { page, limit, search } = filter;
    const grade = gradeFilter?.value as string;
    const flag = flagFilter?.value as string;
    const ageGroup = ageGroupFilter?.value as string;

    const handleVenueId = () => {
      if (withVenue) return venueId;
      if (isWardPatients || isTab) return null;
      return venueId;
    };

    try {
      await fetchAllPatient(
        page,
        limit,
        search as string,
        actorId,
        handleVenueId(),
        grade,
        flag,
        ageGroup
      );
    } catch (error) {
      console.error('Error loading patients:', error);
    } finally {
      setTimeout(() => {
        setLoader({ ...loader, list: false });
      }, 1000);
    }
  };

  const fetchNecessaryData = async () => {
    try {
      if (
        locationLevels.length <= 0 ||
        locations.length <= 0 ||
        gradeOptions.length <= 0
      ) {
        await Promise.all([
          locationLevels.length <= 0
            ? fetchAllLocationLevel()
            : Promise.resolve(),
          locations.length <= 0 ? fetchAllLocation() : Promise.resolve(),
          gradeOptions.length <= 0
            ? fetchAllFormOptionItem('grade')
            : Promise.resolve()
        ]);
      }
      let tempActivityType;
      let tempActor;

      if (activityTypeId && actorId) {
        [tempActivityType, tempActor] = await Promise.all([
          fetchOneActivityType(parseInt(activityTypeId)),
          fetchOneActor(parseInt(actorId))
        ]);
      }

      const selectedVenueIdTemp =
        actor && tempActivityType?.has_locations_tab
          ? tempActor?.default_venue_id
          : parseInt(venueId as string);
      if (selectedVenueIdTemp) await setDefaultVenue(selectedVenueIdTemp, true);
    } catch (error) {
      console.error('Error fetching necessary data:', error);
      throw error;
    }
  };
  const setDefaultVenue = async (
    selectedVenueIdTemp: number,
    init = false,
    isEdit = false
  ) => {
    try {
      let tempVenue;

      if (selectedVenueIdTemp) {
        tempVenue = await fetchOneVenue(selectedVenueIdTemp);
      }
      setVenue(tempVenue);
      if (!isEdit) {
        setPatient({
          ...patient,
          venue_id: selectedVenueIdTemp
        });
      }

      if (init) setActivityVenue(tempVenue);
      return true;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setFilter({ ...filter, search: searchInput, page: 1 });
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchInput]);

  useEffect(() => {
    loadPatients().catch(e => console.log(e));
  }, [activityVenue, filter, gradeFilter, flagFilter, ageGroupFilter]);

  const handleOnAdd = () => {
    if (!activityVenue) {
      handleDialogForMissingDefaultVenue();
    } else {
      setVenue(activityVenue as Venue);
      setValidationError({ show: false });
      if (actor) {
        setPatient({
          ...initialFormValues,
          ...{ actor_id: actor?.id, location: actor?.location }
        });
      }

      setOffCanvas({ ...offCanvas, ...{ show: true } });
    }
  };

  const handleOnEdit = (data: PatientType) => {
    if (data?.venue_id) {
      setDefaultVenue(data?.venue_id as number, false, true).catch(e =>
        console.log(e)
      );
      setValidationError({ show: false });
      fetchOneItem(data);
      setOffCanvas({ ...offCanvas, ...{ show: true } });
    }
  };

  const handleOnView = (data: PatientType) => {
    if (!activityVenue) {
      handleDialogForMissingDefaultVenue();
    } else {
      setVenue(activityVenue as Venue);
      setOffCanvasDetail({ ...offCanvasDetail, ...{ show: true } });
      fetchOneItem(data);
    }
  };
  const handleDialogForMissingDefaultVenue = () => {
    confirmAlert({
      icon: faWarning,
      confirmLabel: 'ok',
      title: `${t('component.dialog.default_venue_missing.title')}`,
      message: `${t('component.dialog.default_venue_missing.body', { name: actor?.name as string })}`
    }).then(resp => {
      console.log(resp);
    });
  };

  const handleOnFlag = (data: PatientType) => {
    if (data.is_flagged) {
      const name = getFullName(data);
      confirmAlert({
        icon: faFlag,
        confirmLabel: 'yes',
        cancelLabel: 'no',
        title: `${t('component.dialog.flag.title')}`,
        message: data?.is_flagged
          ? `${t('component.dialog.unflag.body', { name })}`
          : `${t('component.dialog.flag.body', { name })}`
      }).then(resp => {
        if (resp && data) {
          flagItem(data, { is_flagged: 0, flag_reason: '' });
        }
      });
    } else {
      setOffCanvasFlag({ ...offCanvasFlag, ...{ show: true } });
      setPatient(data);
    }
  };
  const handleOnSubmitFlag = (data: PatientFlag) => {
    if (patient?.id) {
      flagItem(patient, data);
    }
  };

  const flagItem = (patient: PatientType, data: PatientFlag) => {
    const name = getFullName(patient);
    setLoader({ list: true });
    if (patient.id) {
      flagPatient(patient.id, data)
        .then(() => {
          if (!data.is_flagged) {
            toast.success(t('common.message.success.flag', { page, name }));
          } else {
            toast.success(t('common.message.success.unflag', { page, name }));
          }
          setOffCanvasFlag({ ...offCanvasFlag, ...{ show: false } });
          setLoader({ list: false });
        })
        .catch(e => {
          console.log(e);
          toast.error(t('common.message.failed'));
          setLoader({ list: false });
        });
    }
  };

  const fetchOneItem = (row: PatientType) => {
    setLoader({ ...loader, list: true });
    setPatient(row);
    setLoader({ ...loader, list: false });
  };

  const createItem = (data: PatientType) => {
    const name = getFullName(data);
    setOffCanvas({ ...offCanvas, ...{ show: true } });
    createPatient(data)
      .then(() => {
        toast.success(
          t('common.message.success.create', { page: patientTitle, name })
        );
        setPatient(initialFormValues);
        setValidationError({ show: false });
        setOffCanvas({ ...offCanvas, ...{ show: false } });
        setLoader({ ...loader, form: false });
      })
      .catch(e => {
        console.log(e);
        if (e.status === 422) {
          setValidationError({
            show: true,
            errors: e?.data?.errors
          });
          toast.error(t('common.message.failed.validation'));
        } else {
          toast.error(e.data?.message || t('common.message.failed'));
        }
        setLoader({ ...loader, form: false });
      });
  };
  const updateItem = (id: number, data: PatientType) => {
    const name = getFullName(data);
    setOffCanvas({ ...offCanvas, ...{ show: true } });
    updatePatient(id, data)
      .then(res => {
        toast.success(
          t('common.message.success.update', { page: patientTitle, name })
        );
        setPatient(res);
        setOffCanvas({ ...offCanvas, ...{ show: false } });
        setValidationError({ show: false });
        setLoader({ ...loader, form: false });
      })
      .catch(e => {
        if (e.status === 422) {
          setValidationError({
            show: true,
            errors: e?.data?.errors
          });
          toast.error(t('common.message.failed.validation'));
        } else {
          toast.error(e.data?.message || t('common.message.failed'));
        }
        setLoader({ ...loader, form: false });
      });
  };

  const handleOnSubmit = (formData: PatientType) => {
    setLoader({ ...loader, form: true });
    if (formData.id) {
      updateItem(formData.id, formData);
    } else {
      createItem(formData);
    }
  };

  const mappedTable = () => {
    const tempTable: UseAdvanceTableProps<PatientType> = {
      data: patients,
      columns: activityTypePatientsTableColumns(handleOnView),
      pageSize: filter.limit,
      pagination: true,
      sortable: true,
      pageCount: pageCount(meta.total_rows, filter.limit),
      manualPagination: true,
      onPaginationChange: updater => {
        const newState =
          typeof updater === 'function'
            ? updater(table.getState().pagination)
            : updater;
        table.getState().pagination.pageIndex = newState.pageIndex;
        table.getState().pagination.pageSize = newState.pageSize;
        setFilter({
          ...filter,
          page: newState.pageIndex + 1,
          limit: newState.pageSize
        });
      }
    };
    if (checkScope('patient-update')) {
      tempTable.onEdit = data => {
        handleOnEdit(data);
      };
    }
    if (checkScope('patient-read')) {
      tempTable.onView = data => {
        handleOnView(data);
      };
    }
    if (checkScope('patient-delete')) {
      tempTable.onFlag = data => {
        handleOnFlag(data);
      };
      // tempTable.onDelete = data => {
      //   handleOnDelete(data);
      // };
    } else {
      tempTable.onFlag = data => {
        handleOnFlag(data);
      };
    }
    return tempTable;
  };
  const table = useAdvanceTable(mappedTable());

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInput(value);
  };

  const handleFilterReset = () => {
    table.setPageIndex(0);
    setSearchInput('');
    setFilter({ ...filter, search: '' });
    setGradeFilter(null);
    setFlagFilter(null);
    setAgeGroupFilter(null);
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                value={searchInput}
                placeholder={`${t('common.label.search_by_name')}`}
                onChange={handleSearchInputChange}
              />
            </Col>
            {gradeOptions?.length > 0 && (
              <Col xs={2} className="react-select">
                <ReactSelect
                  value={gradeFilter}
                  options={gradeOptions}
                  name="gradeFilter"
                  onChange={option => {
                    const selectedOption = option as SelectOptionType;
                    setGradeFilter(selectedOption);
                  }}
                  placeholder={`${t('common.label.grade')}`}
                  isSearchable={false}
                />
              </Col>
            )}
            {isTenantJevaia() && (
              <Col xs={2}>
                <ReactSelect
                  value={ageGroupFilter}
                  options={ageGroupOptions}
                  name="ageRangeFilter"
                  onChange={option => {
                    const selectedOption = option as SelectOptionType;
                    setAgeGroupFilter(selectedOption);
                  }}
                  placeholder={`${t('common.label.age_group')}`}
                />
              </Col>
            )}

            <Col xs={2}>
              <ReactSelect
                value={flagFilter}
                options={flagOptions}
                name="flagFilter"
                onChange={option => {
                  const selectedOption = option as SelectOptionType;
                  setFlagFilter(selectedOption);
                }}
                placeholder={`${t('common.label.flag_status')}`}
              />
            </Col>
            <Col xs={2}>
              <Button
                variant="phoenix-secondary"
                className="reset-btn"
                onClick={handleFilterReset}
              >
                <FontAwesomeIcon icon={faRotate} className="me-2" />
                {t('common.action.reset')}
              </Button>
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              {checkScope('patient-create') && (
                <Button variant="primary" onClick={handleOnAdd}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('common.action.add')} {patientTitle}
                </Button>
              )}
            </Col>
          </Row>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-translucent position-relative top-1 flex-grow-1">
          {loader.list ? (
            <PhoenixLoader />
          ) : (
            <ActivityTypePatientsTable size={patients?.length} />
          )}
        </div>
      </AdvanceTableProvider>

      {venue && (
        <>
          <PatientDetail
            data={patient}
            venueId={venue?.id as number}
            offCanvas={offCanvasDetail}
            onEdit={value => {
              handleOnEdit(value);
            }}
            onClose={() => {
              setOffCanvasDetail({ ...offCanvasDetail, ...{ show: false } });
            }}
          />
        </>
      )}
      <PatientForm
        formData={patient}
        venues={venue ? [venue] : []}
        actors={[actor]}
        locations={locations}
        locationLevels={locationLevels}
        offCanvas={offCanvas}
        onSubmit={values => {
          handleOnSubmit(values);
        }}
        onClose={() => {
          setOffCanvas({ ...offCanvas, ...{ show: false } });
        }}
        validationError={{
          ...validationError,
          onClose: () => {
            setValidationError({ show: false });
          }
        }}
        loading={loader.form}
        activityType={activityType}
      />
      <PatientFlagForm
        patient={patient}
        offCanvas={offCanvasFlag}
        onSubmit={values => {
          handleOnSubmitFlag(values);
        }}
        onClose={() => {
          setOffCanvasFlag({ ...offCanvasFlag, ...{ show: false } });
        }}
        loading={loader?.flag as boolean}
      />
    </div>
  );
};

export default ActivityTypePatients;
